import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Button, Navbar, Spinner, Modal, Carousel, ModalHeader } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import apinode from './apinode';
import './App.css'; // Importa o arquivo CSS para os estilos responsivos
import ProductTable from './table.jsx'; // Ajuste o caminho se necessário
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ProductCatalog = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [show, setShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showFOB, setShowFOB] = useState(false);

    useEffect(() => {
        apinode.get('/estoque/catalogo/000000001')
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    const handleShow = (index) => {
        setActiveIndex(index);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const handleShowFOB = () => setShowFOB(true);
    const handleCloseFOB = () => setShowFOB(false);

    const calculateFOBPrices = (product) => {
        return {
            ...product,
            precovenda: product.precovenda * 1.12,
            precoatacado: product.precoatacado * 1.12,
        };
    };

    const filteredProducts = products.filter(product =>
        product.descricao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.codinterno?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.sku?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.ean?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredProductsFOB = filteredProducts.map(calculateFOBPrices);

    const handleExport = () => {
        const worksheetCIF = XLSX.utils.json_to_sheet(filteredProducts.map(product => ({
            Ref: product.codinterno,
            SKU: product.sku,
            EAN: product.ean,
            Descrição: product.descricao,
            'Preço Varejo (FOB)': product.precovenda.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
            'Preço Atacado (FOB)': product.precoatacado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
        })));

        const worksheetFOB = XLSX.utils.json_to_sheet(filteredProductsFOB.map(product => ({
            Ref: product.codinterno,
            SKU: product.sku,
            EAN: product.ean,
            Descrição: product.descricao,
            'Preço Varejo (CIF)': product.precovenda.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
            'Preço Atacado (CIF)': product.precoatacado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheetFOB, 'Products FOB');
        XLSX.utils.book_append_sheet(workbook, worksheetCIF, 'Products CIF');
        XLSX.writeFile(workbook, 'Products.xlsx');
    };

  
    async function getBase64ImageFromUrl(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/jpeg'));
            };
            img.onerror = reject;
            img.src = url;
        });
    }
    
    const handlePrintPDF = async () => {
        setLoading(true);
    
        const productsWithImages = await Promise.all(
            filteredProducts.map(async (product) => {
                try {
                    const imageBase64 = await getBase64ImageFromUrl(`https://node.ziphub.com.br/imagens/${product.img_path}`);
                    return { ...product, imageBase64 };
                } catch (error) {
                    console.error("Erro ao carregar imagem", error);
                    return { ...product, imageBase64: null };
                }
            })
        );
    
        const content = productsWithImages.map(product => {
            const priceRows = [];
    
            // Preço Varejo com label "Varejo Promoção" se for promocional
            if (product.precovarejo_p > 0) {
                priceRows.push({
                    text: `Varejo Promoção: R$ ${product.precovarejo_p.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
                    style: 'productPricePromo'
                });
            } else {
                priceRows.push({
                    text: `Varejo: R$ ${(product.precovenda || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
                    style: 'productPrice'
                });
            }
    
            // Preço Atacado com label "Atacado Promoção" se for promocional
            if (product.precoatacado_p > 0) {
                priceRows.push({
                    text: `Atacado Promoção: R$ ${product.precoatacado_p.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
                    style: 'productPricePromo'
                });
            } else {
                priceRows.push({
                    text: `Atacado: R$ ${(product.precoatacado || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
                    style: 'productPrice'
                });
            }
    
            return {
                width: 'auto',
                stack: [
                    {
                        columns: [
                            {
                                width: 80,
                                image: product.imageBase64,
                                height: 80,
                                margin: [0, 0, 10, 0]
                            },
                            {
                                width: '*',
                                stack: [
                                    { text: product.descricao, style: 'productTitle' },
                                    { text: `Ref: ${product.codinterno}`, style: 'productInfo' },
                                    { text: `SKU: ${product.sku}`, style: 'productInfo' },
                                    { text: `EAN: ${product.ean}`, style: 'productInfo' },
                                    ...priceRows
                                ]
                            }
                        ],
                    },
                    // Linha separadora suave
                    { 
                        text: '', 
                        style: 'separatorLine' 
                    }
                ],
                margin: [0, 0, 0, 10],  // Ajustando o espaçamento entre os itens
            };
        });
    
        // Definição do documento PDF
        const docDefinition = {
            content: [
                { text: "Catálogo de Produtos - Belalumen", style: "header" },
                { text: "Lista de produtos com preços FOB", style: "subheader" },
                ...content
            ],
            footer: {
                text: `Data de Impressão: ${new Date().toLocaleDateString('pt-BR')}`,
                alignment: 'center',
                margin: [0, 10]
            },
            styles: {
                header: {
                    fontSize: 22, // Reduzido
                    bold: true,
                    alignment: "center",
                    margin: [0, 20, 0, 10],
                    color: '#1E1E1E'
                },
                subheader: {
                    fontSize: 12, // Reduzido
                    italics: true,
                    alignment: "center",
                    margin: [0, 0, 0, 20],
                    color: '#555555'
                },
                productTitle: {
                    fontSize: 14, // Reduzido
                    bold: true,
                    color: '#333333',
                    margin: [0, 0, 0, 3] // Menos espaço entre o título e o próximo item
                },
                productInfo: {
                    fontSize: 10, // Reduzido
                    color: '#555555',
                    margin: [0, 0, 0, 1] // Menos espaço entre o texto
                },
                productPrice: {
                    fontSize: 12, // Reduzido
                    color: '#333333', // Cor normal para preço
                    margin: [0, 4, 0, 2]
                },
                productPricePromo: {
                    fontSize: 12, // Reduzido
                    color: '#fff', // Cor branca para o texto da tag promocional
                    background: '#d39e00', // Cor mostarda para a tag promocional
                    padding: [3, 5],
                    borderRadius: 5,
                    margin: [0, 4, 0, 2],
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    alignment: 'center'
                },
                separatorLine: {
                    borderBottom: '1px solid #ddd', // Linha separadora suave
                    margin: [0, 10, 0, 10] // Espaço antes e depois da linha
                }
            },
            pageMargins: [20, 20, 20, 40], // Menos margens para aproveitar o espaço
            pageSize: 'A4', // Definir tamanho da página
            pageOrientation: 'portrait' // Orientação vertical
        };
    
        pdfMake.createPdf(docDefinition).download("tabela_belalumen.pdf");
        setLoading(false);
    };
    
    

    const handlePrintFOB = () => {
        setLoading(true);
        if (isMobile()) {
            handlePrintPDFMobile('catalogo_fob');
        } else {
            setLoading(true);

            html2canvas(document.getElementById('catalogFOB'), {
                scale: 1,
                useCORS: true,
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png', 0.3);

                const pdfWidth = 595.28;
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

                const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);

                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

                const pdfBlob = pdf.output('blob');
                const pdfURL = URL.createObjectURL(pdfBlob);

                window.open(pdfURL);

                uploadPDF(pdfBlob, 'catalogo_fob.pdf');
                setLoading(false);
            }).catch(error => {
                console.error('Error generating PDF: ', error);
                setLoading(false);
                alert('Houve um problema ao gerar o PDF. Por favor, tente novamente.');
            });
        }
    };

    const isMobile = () => {
        return window.innerWidth <= 768; // or use any other condition to detect mobile devices
    };



    const handlePrintPDFMobile = (tipo) => {
        setLoading(true);

        apinode.get(`/assets/${tipo}.pdf`, { responseType: 'blob' })
            .then(response => {
                const fileURL = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', 'catalogo.pdf');
                document.body.appendChild(link);

                setTimeout(() => {
                    link.click();
                    document.body.removeChild(link);
                    setLoading(false);
                }, 100);
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                setLoading(false);
                alert('Houve um problema ao obter o PDF. Por favor, tente novamente.');
            });
    };

    const uploadPDF = (pdfBlob, fileName) => {
        const formData = new FormData();
        formData.append('file', pdfBlob, fileName);

        apinode.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                console.log('Arquivo enviado com sucesso:', response.data);
                alert('PDF enviado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao enviar o arquivo:', error);
                alert('Houve um problema ao enviar o PDF. Por favor, tente novamente.');
            });
    };

    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src="BelaLumen_Preto.png"
                        width="200"
                        height="90"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
            </Navbar>
            <div className="container mt-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h1>Tabela de Produtos</h1>
                    <div className="d-flex flex-wrap">
                        <Button variant="primary" onClick={handlePrintPDF} disabled={loading} className="me-2 mb-2">
                            {loading ? (
                                <>
                                    <Spinner animation="border" size="sm" /> Carregando...
                                </>
                            ) : (
                                'Baixar PDF FOB'
                            )}
                        </Button>
                        <Button onClick={handleExport} className="btn btn-warning me-2 mb-2">
                            Exportar para Excel
                        </Button>
                        <Button variant="success" onClick={handleShowFOB} className="mb-2">
                            Visualizar Tabela CIF
                        </Button>
                    </div>
                </div>

                {/* <input
                    type="text"
                    placeholder="Pesquisar..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="form-control mb-3"
                /> */}

                <ProductTable filteredProducts={filteredProducts} />

            </div>

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body>
                    <Carousel activeIndex={activeIndex} onSelect={() => { }}>
                        {filteredProducts.map(product => (
                            <Carousel.Item key={product.id}>
                                <img
                                    className="d-block w-100"
                                    src={`https://node.ziphub.com.br/imagens/${product.img_path}`}
                                    alt={product.descricao}
                                />
                                {/* <Carousel.Caption>
                                    <h3>{product.descricao}</h3>
                                    <p>Ref: {product.codinterno} | SKU: {product.sku} | EAN: {product.ean}</p>
                                    <p>Preço Varejo: R${product.precovenda.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                    <p>Preço Atacado: R${product.precoatacado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                </Carousel.Caption> */}
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>

            <Modal show={showFOB} onHide={handleCloseFOB} centered size="lg">
                <Modal.Body>
                    <Modal.Header>
                        <Modal.Title>Tabela CIF</Modal.Title>
                        <div className="ms-auto">
                            <Button onClick={handlePrintFOB} variant="primary">
                                {loading ? (
                                    <>
                                        <Spinner animation="border" size="sm" /> Carregando...
                                    </>
                                ) : (
                                    'Baixar PDF CIF'
                                )}
                            </Button>
                        </div>
                    </Modal.Header>
                    <div className="table-responsive">
                        <table id="catalogFOB" className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Ref.</th>
                                    <th>SKU</th>
                                    <th>EAN</th>
                                    <th>Descrição</th>
                                    <th>Preço Varejo CIF</th>
                                    <th>Preço Atacado CIF</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProductsFOB.map((product, index) => (
                                    <tr key={product.id}>
                                        <td>
                                            <img
                                                src={`https://node.ziphub.com.br/imagens/${product.img_path}`}
                                                alt={product.codinterno}
                                                style={{ maxWidth: '50px', maxHeight: '50px' }}
                                            />
                                        </td>
                                        <td>{product.codinterno}</td>
                                        <td>{product.sku}</td>
                                        <td>{product.ean}</td>
                                        <td>{product.descricao}</td>
                                        <td>
                                            {product.precovarejo_p > 0 ? (
                                                <span className="badge bg-warning text-dark">
                                                    R${(product.precovarejo_p * 1.12).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </span>
                                            ) : (
                                                <span>
                                                    R${(product.precovenda).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            {product.precoatacado_p > 0 ? (
                                                <span className="badge bg-warning text-dark">
                                                    R${(product.precoatacado_p * 1.12).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </span>
                                            ) : (
                                                <span>
                                                    R${(product.precoatacado).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </span>
                                            )}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProductCatalog;
