import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Modal, Button } from 'react-bootstrap';

const ProductTable = ({ filteredProducts }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const productsPerPage = 10;

    const filtered = filteredProducts.filter(product =>
        product.descricao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.codinterno?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.sku?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.ean?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getDisplayedProducts = () => {
        const offset = currentPage * productsPerPage;
        return filtered.slice(offset, offset + productsPerPage);
    };

    const handleImageClick = (product) => {
        setSelectedProduct(product);
    };

    const handleClose = () => {
        setSelectedProduct(null);
    };

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    return (
        <div>
            {/* Campo de pesquisa */}
            <input
                type="text"
                placeholder="Pesquisar..."
                value={searchTerm}
                onChange={e => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(0);
                }}
                className="form-control mb-3"
            />

            <div className="table-responsive">
                <table id="catalog" className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Ref.</th>
                            <th>SKU</th>
                            <th>EAN</th>
                            <th>Descrição</th>
                            <th>Preço Varejo</th>
                            <th>Preço Atacado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getDisplayedProducts().map((product) => (
                            <tr key={product.id}>
                                <td>
                                    <img
                                        src={`https://node.ziphub.com.br/imagens/${product.img_path}`}
                                        alt={product.codinterno}
                                        style={{ maxWidth: '50px', maxHeight: '50px', cursor: 'pointer' }}
                                        onClick={() => handleImageClick(product)}
                                    />
                                </td>
                                <td>{product.codinterno}</td>
                                <td>{product.sku}</td>
                                <td>{product.ean}</td>
                                <td>{product.descricao}</td>
                                <td>
                                    {product.precovarejo_p > 0 ? (
                                        <span className="badge bg-warning text-dark">
                                            R${product.precovarejo_p.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                        </span>
                                    ) : (
                                        <span>
                                            R${(product.precovenda || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    {product.precoatacado_p > 0 ? (
                                        <span className="badge bg-warning text-dark">
                                            R${product.precoatacado_p.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                        </span>
                                    ) : (
                                        <span>
                                            R${(product.precoatacado || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Paginação */}
            <ReactPaginate
                previousLabel={"← Anterior"}
                nextLabel={"Próximo →"}
                breakLabel={"..."}
                pageCount={Math.ceil(filtered.length / productsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                previousClassName={"previous"}
                nextClassName={"next"}
                disabledClassName={"disabled"}
            />

            {/* Modal para exibir imagem ampliada */}
            {selectedProduct && (
                <Modal show={true} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedProduct.descricao}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <img
                            src={`https://node.ziphub.com.br/imagens/${selectedProduct.img_path}`}
                            alt={selectedProduct.codinterno}
                            style={{ maxWidth: '100%', maxHeight: '500px' }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default ProductTable;
